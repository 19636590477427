import {DownloadOutlined} from '@ant-design/icons';
import {Modal} from 'antd';
import api from '../../api';
import CRUD from '../../components/CRUD';
import {formatDate} from '../../utils/normalize';
import MeetingInvoiceTemplateForm from '../MeetingControl/MeetingInvoiceTemplateForm';
import InvoiceCreateForm from './InvoiceCreateForm';
import InvoiceForm from './InvoiceForm';
import InvoicePDF from './InvoicePDF';
import InvoiceSendingForm from './InvoiceSendingForm';
import InvoiceSendingOneForm from './InvoiceSendingOneForm';
import InvoiceDeleteAllForm from './InvoiceDeleteAllForm';

const InvoiceList = ({searchParams}) => {

const getAllPDF = e => {
  api.invoice.invoiceAllPDF({
    filename: `invoices-${searchParams.meeting}.pdf`,
    params: {
      meetingId: searchParams.meeting,
    }
  });
}

const deleteAll = e => {
  api.invoice.invoiceDeleteAll({
    params: {
      meetingId: searchParams.meeting,
  }});
}


function info(row) {
  Modal.info({
    title: 'Error',
    content: (
      <div>
        <p>{row.error}</p>
      </div>
    ),
    onOk() {},
  });
}

  const columns = [
    {
      title: 'Frau/Herr',
      dataIndex: 'greeting',
      key: 'greeting',
    },
    {
      title: 'Nachname',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: 'Vorname',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Teilnehmerstatus',
      dataIndex: 'status_name',
      key: 'status_name',
      sorter: (a, b) => a.status_name.localeCompare(b.status_name),
    },
    {
      title: 'Kosten',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price.localeCompare(b.price),
    },
    {
      title: 'Versandstatus',
      dataIndex: 'is_success',
      key: 'is_success',
      render: ((text, row) => {
        if (text === false || text === true) {
          return text ? 'Erfolg' : <a onClick={()=>info(row)}>Error</a>;
        }
      }),
      sorter: (a, b) => a - b,
    },
    {
      title: 'Versanddatum',
      dataIndex: 'date_sent',
      key: 'date_sent',
      render: (text => {
        return text ? formatDate(text, 'YYYY-MM-DD HH:mm:ss', 'DD.MM.YYYY HH:mm:ss') : ''
      }),
      sorter: (a, b) => a.date_sent > b.date_sent,
    },
  ];
  return <>
    <CRUD
    keyName="invoice"
    title="Rechnungen"
    FormComponent={InvoiceForm}
    tableColumns={columns}
    searchParams={searchParams}
    itemValueName="last_name"
    apiList={api.invoice.invoiceList}
    apiRetrieve={api.invoice.invoiceRetrieve}
    apiDelete={api.invoice.invoiceDelete}
    listRefetchInterval={10000}
    extraFields={{meeting: searchParams['meeting']}}
    childrenActions={{'DownloadPDF': {
        childrenActionName: 'Herunterladen PDF',
        ChildrenCRUD: InvoicePDF,
      },
      'Send': {
        childrenActionName: 'Erneut senden',
        ChildrenCRUD: InvoiceSendingOneForm,
      },
    }}
    commonActions={{
      'generate': {
        ActionComponent: InvoiceCreateForm,
        buttonLabel: 'Rechnungen erstellen',
        extraProps: {
          parentId: searchParams['meeting'],
        },
        width: 900,
      },
      'delete': {
        ActionComponent: InvoiceDeleteAllForm,
        buttonLabel: 'Alles löschen',
        extraProps: {
          parentId: searchParams['meeting'],
        },
        width: 900,
      },
      'template': {
        ActionComponent: MeetingInvoiceTemplateForm,
        buttonLabel: 'Rechnungsvorlage',
        buttonType: 'secondary',
        extraProps: {
          parentId: searchParams['meeting'],
        },
        width: 900,
      },
      'sending': {
        ActionComponent: InvoiceSendingForm,
        buttonLabel: 'E-Mail schicken',
        buttonType: 'secondary',
        extraProps: {
          meeting: searchParams['meeting'],
        },
        width: 700,
      },
      'report': {
        buttonLabel: 'PDF mit allen Rechnungen herunterladen',
        buttonType: 'secondary',
        buttonExtraProps: {
          icon: <DownloadOutlined/>,
          onClick: getAllPDF,
          shape: "round"
        },
        width: 700,
      },
    }}
  /></>
};

export default InvoiceList;
